<template>
      <div class="">
      <div class="d-flex mb-2">
          <span class="page-header-title">Service Zonal Manager Details</span>
    </div>
  <CCard>
    <CCardBody>
      <button type="button" class="btn btn-outline-info btn-sm" @click="
        (e) => {
          this.$router.go(-1);
        }
      ">
        Back
      </button>
      <button type="button" class="btn btn-outline-primary btn-sm ml-3" @click="editTableData()">
        Edit
      </button>

      <div class="row mt-3 mb-3">
        <div class="col-md-3" style="font-size: 16px; font-weight: bold">
          Name
        </div>
        <div class="col-md-9">
          {{ userObj.name }}
        </div>
      </div>

      <div class="row mt-3 mb-3">
        <div class="col-md-3" style="font-size: 16px; font-weight: bold">
          Email ID
        </div>
        <div class="col-md-9">
          {{ userObj.email }}
        </div>
      </div>

      <div class="row mt-3 mb-3">
        <div class="col-md-3" style="font-size: 16px; font-weight: bold">
          Phone Number
        </div>
        <div class="col-md-9">
          {{ userObj.phone_no }}
        </div>
      </div>

      <div class="row mt-3 mb-3">
        <div class="col-md-3" style="font-size: 16px; font-weight: bold">
          Designation
        </div>
        <div class="col-md-9">
          {{ userObj.designation }}
        </div>
      </div>

      <div class="row mt-3 mb-3">
        <div class="col-md-3" style="font-size: 16px; font-weight: bold">
          Company Name
        </div>
        <div class="col-md-9">
          {{ userObj.company_name }}
        </div>
      </div>

      <div class="row mt-3 mb-3">
        <div class="col-md-3" style="font-size: 16px; font-weight: bold">
          Company Address
        </div>
        <div class="col-md-9">
          {{ userObj.company_address }}
        </div>
      </div>

      <div class="row mt-3 mb-3">
        <div class="col-md-3" style="font-size: 16px; font-weight: bold">
          Country
        </div>
        <div class="col-md-9">
          {{ userObj.country }}
        </div>
      </div>

      <div class="row mt-3 mb-3">
        <div class="col-md-3" style="font-size: 16px; font-weight: bold">
          State
        </div>
        <div class="col-md-9">
          {{ userObj.state }}
        </div>
      </div>

      <div class="row mt-3 mb-3">
        <div class="col-md-3" style="font-size: 16px; font-weight: bold">
          City
        </div>
        <div class="col-md-9">
          {{ userObj.city }}
        </div>
      </div>

      <div class="row mt-3 mb-3">
        <div class="col-md-3" style="font-size: 16px; font-weight: bold">
          PinCode
        </div>
        <div class="col-md-9">
          {{ userObj.postal_code }}
        </div>
      </div>

      <div class="row mt-3 mb-3">
        <div class="col-md-3" style="font-size: 16px; font-weight: bold">
          Region
        </div>
        <div class="col-md-9">
          {{ userObj.region_name }}
        </div>
      </div>
    </CCardBody>
  </CCard>
  </div>
</template>

<script>
import _ from "lodash";
import { MANAGER_DETAILS } from "@/store/actions.type";
export default {
  name: "ServiceRequestDetails",
  components: {},
  data() {
    return {
      managerId: 0,

      userObj: {
        name: "",
        email: "",
        phone_no: "",
        company_name: "",
        company_address: "",
        country: "",
        state: "",
        city: "",
        postal_code: "",
        region_id: "",
        profile_photo: "",
        region_name: "",
        country_code: "",
        designation: "",
      },
      imagesArray: [],
      currentPage: 1,
      pageStart: 0,
      perPage: 50,
      total: 0,
      sortField: "created_at",
      sortDirection: "desc",
      del_account_name: "",
    };
  },

  methods: {
    changeColor() {
      document.documentElement.style.setProperty("--sidebarBgColor", "red");
    },

    /**
     * @method editTableData
     * @description Opens edit popup
     * @author @Jeevan Poojary
     */
    editTableData() {
      this.$router.push({
        name: "manageredit",
        params: { id: this.managerId },
      });
    },

    /**
     * @method getManagerDetails
     * @description get manager details
     * @author @Jeevan Poojary
     */

    async getManagerDetails() {
      if (this.managerId) {
        this.isLoadingManagersDetails = this.$loading.show();
        await this.$store
          .dispatch(MANAGER_DETAILS, {
            id: this.managerId,
          })
          .then((data) => {
            if (data.success) {
              let detailsDataObj = _.get(data, "managerData", [])
              if(detailsDataObj){
              this.userObj = detailsDataObj;
              let userDetails = this.userObj.user_profile;
              this.userObj.company_name =
                userDetails.company_name != null ? userDetails.company_name : "-";
              this.userObj.designation =
                userDetails.designation != null ? userDetails.designation : "-";
              this.userObj.company_address =
                userDetails.company_address != null
                  ? userDetails.company_address
                  : "-";
              this.userObj.country =
                userDetails.country != null ? userDetails.country : "-";
              if (this.userObj.country == 0) {
                this.userObj.country = "India";
              } else if (this.userObj.country == 1) {
                this.userObj.country = "Sri Lanka";
              } else if (this.userObj.country == 2) {
                this.userObj.country = "Bangladesh";
              }
              this.userObj.state =
                userDetails.state != null ? userDetails.state : "-";
              this.userObj.city = userDetails.city != null ? userDetails.city : "-";
              this.userObj.postal_code =
                userDetails.postal_code != null ? userDetails.postal_code : "-";
              this.userObj.phone_no =
                userDetails.phone_no != null ? userDetails.phone_no : null;

              this.userObj.country_code =
                userDetails.country_code != null ? userDetails.country_code : null;
              if (
                this.userObj.country_code != null &&
                this.userObj.phone_no == null
              ) {
                this.userObj.phone_no = this.userObj.country_code;
              } else if (
                this.userObj.country_code == null &&
                this.userObj.phone_no != null
              ) {
                this.userObj.phone_no = detailsDataObj.phone_no;
              } else if (
                this.userObj.country_code == null &&
                this.userObj.phone_no == null
              ) {
                this.userObj.phone_no = "-";
              } else {
                this.userObj.phone_no =
                  this.userObj.country_code + " " + this.userObj.phone_no;
              }
              let regionObj = detailsDataObj.region_name ? detailsDataObj.region_name : "";
              if(regionObj){
                this.userObj.region_name =
                regionObj.name != null ? regionObj.name : "-";
              } else {
                this.userObj.region_name = "-";
              }
          
              }
              this.isLoadingManagersDetails.hide();
            } else {
              this.$toast.removeAll();
              this.$toast.error("ID does not exist. ");
              this.isLoadingManagersDetails.hide();
              this.$router.push({
                name: "managerlist",
              });
            }
            this.isLoadingManagersDetails.hide();
          });
      }

    },
  },
  async created() {
    // this.managerId = _.get(this.$route, "params.id", 0);
    this.managerId = this.$store.state.selectedManagers || 0;
    this.getManagerDetails();
  },
};
</script>
